<template>
<v-card class="pr-4" :class="$router.currentRoute.path == '/cdslmobile' ? 'authMobileAlign h-100' : ''" id="cdsl_card">
        <v-card-title>
          <div class="row ma-0 pa-0">
            <v-flex xs12 sm12 md5 lg5 xl5 class="pa-0">
              <img src="../assets/images/cdsl.svg" alt="" id="cdsl_img" />
            </v-flex>
            <v-flex xs12 sm12 md7 lg7 xl7 class="pa-0">
              <div class="fsize14" id="cdsl_content_0">e-DIS Transaction Verification</div>
              <div class="fsize14" id="cdsl_content_1">Convenient. Dependable. Secure</div>
            </v-flex>
          </div></v-card-title
        >
        <v-card-text :style="$router.currentRoute.path == '/cdslmobile' ? '' : 'max-height: 400px'"  :class="$router.currentRoute.path == '/cdslmobile' ? 'scrollModifty overflow-y-auto' : 'scrollModifty'">
          <div
            class="row rounded px-4 py-2"
            :style="$vuetify.theme.dark ? '' :'background-color: #e8f4fd'"
            :class="$store.state.windowWidth < 375 ? 'flex-column' : 'flex-row'"
          >
            <v-flex xs1 sm1 md1 lg1 xl1 class="pa-0 mt-2">
              <customIcon
                :name="'info'"
                class="mx-1 cursor"
                :width="'21'"
                :height="'21'"
                :color="$store.state.amogaColor"
              />
            </v-flex>
            <v-flex xs11 sm11 md11 lg11 xl11 class="pa-0 mt-2" id="cdsl_content_2">
              <div>
                Disclaimer: This is electronic trade slip, which allows us to
                debit your Demat Holding with stocks approved by you. Through
                this authorization, you will be able to sell the stocks for
                delivery. The authorization is valid for one day only. At any
                point of time you can generate or reset your TPIN using the CDSL
                link
                <div>
                  <a
                    href=" https://edis.cdslindia.com/home/generatepin"
                    target="_blank"
                    class="text-decoration-none"
                  >
                    https://edis.cdslindia.com/home/generatepin</a
                  >
                  where it will ask for your BOID and PAN. The user can always
                  regenerate the TPIN using the above CDSL link. To change TPIN
                  use the link
                </div>
                <div>
                  <a
                    href="https://edis.cdslindia.com/home/changepin"
                    target="_blank"
                    class="text-decoration-none"
                    >https://edis.cdslindia.com/home/changepin</a
                  >
                </div>
              </div>
            </v-flex>
          </div>
          <div class="row ma-0 px-2 py-2 " v-if="pludgeUrl == ''" :class="$router.currentRoute.path == '/cdslmobile' ? 'd-flex justify-space-between' : ''">
            <div class="col-2 mt-2 pa-0"><input id="cdsl_content_check_box_all"
              type="checkbox"
              @click="errorAuth = null"
              v-model="checkboxSelectAll"
              :checkecd="selectedCheckbox.length > 0"
              class="tableCheckBox"
            /></div>
            <div class="col-7 mt-2 pa-0"><div class="primaryColor font-weight-bold" id="cdsl_content_Instrument_head">Select Instruments</div> </div>
            <div class="col-3 mt-2 pa-0 text-end"><div class="primaryColor font-weight-bold" id="cdsl_content_Qty_head">Qty</div></div>
          </div>
          <div v-if="pludgeUrl == ''">
            <div class="row ma-0 px-2  py-2 authHover cursor d-flex align-center" :class="$router.currentRoute.path == '/cdslmobile' ? 'justify-space-between' : ''"  v-for="(item, index) in isRevoke ? revocationList : authorizeList" :key="index" @click="setAuthData(item)">
               <div class="col-2 ma-0 pa-0">
                 <input :id="`${index}_cdsl_check_box`"
                  class="tableCheckBox"
                  @click="errorAuth = null"
                  type="checkbox"
                  :value="item"
                  v-model="selectedCheckbox"
                />
               </div>
<!-- @click="isRevoke ? '' : setAuthData(item)" -->
                <div class="col-8 ma-0 pa-0" :class="$vuetify.theme.dark ? 'white--text' : ''" :id="`${index}_cdsl_scrip_name`">{{ item.formatScripName }}</div>
                <!-- v-if="isRevoke" -->
                <div class="col-2 ma-0 pa-0 text-end" :class="$vuetify.theme.dark ? 'white--text' : ''" :id="`${index}_cdsl_qty`"><span > {{ item.SellableQty }} </span>
                  <!-- <v-text-field
                    v-else
                    :id="`${index}_cdsl_qty`"
                    :min="1"
                    :max="item.SellableQty"
                    v-model.number="item.tempQty"
                    dense
                 outlined hide-details
                  class="checkAlign text-lg-right"
                    @keypress="keyPressNumeric($event)"
                  ></v-text-field> -->
                  </div>
            </div>
            
          </div>
        </v-card-text>
        <v-card-actions class="pl-6 pr-2 pt-4 pb-4">
          <div class="d-flex align-center  w-100 justify-space-between">
            <div>
              <div  class="red--text fsize12" style="height: 36px" :id="`cdsl_err_msg`"><span v-if="errorAuth">{{ errorAuth }}</span> </div>
            </div>
            <div class="d-flex">
              <v-btn :id="`cdsl_confirm_btn`"
                depressed
                :max-width="isRevoke ? 140 : 120"
                height="32"
                class="mr-2 white--text fsize14 text-capitalize"
                :loading="cdslLoader"
                :disabled="cdslLoader"
                v-if="pludgeDpId == ''"
                :color="$store.state.buttonThemeColor"
                @click="validateAuthrise"
                >{{ isRevoke ? "Revoke Authorize" : "Authorize" }}</v-btn
              >
              <form
                :action="pludgeUrl"
                name="frmDIS"
                id="contact-form"
                method="post"
                v-if="pludgeDpId"
              >
                <input
                  type="hidden"
                  id="DPId"
                  name="DPId"
                  :value="pludgeDpId"
                />
                <input
                  type="hidden"
                  :id="isRevoke ? 'RevocReqId' : 'ReqId'"
                  :name="isRevoke ? 'RevocReqId' : 'ReqId'"
                  :value="pludgeReqId"
                />
                <input
                  type="hidden"
                  id="Version"
                  name="Version"
                  :value="pludgeVersion"
                />
                <input
                  type="hidden"
                  :id="isRevoke ? 'RevokDtls' : 'TransDtls'"
                  :name="isRevoke ? 'RevokDtls' : 'TransDtls'"
                  :value="pludgeTransId"
                />
                <v-btn
                  type="submit"
                  height="32"
                  min-width="80"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  class="fsize14 mr-2 text-capitalize white--text"
                  >Continue to CDSL</v-btn
                >
              </form>
              <v-btn id="cdsl_close_btn"
              v-if="$router.currentRoute.path !== '/cdslmobile'"
                outlined
                height="32"
                min-width="80"
                color="#9b9b9b"
                depressed
                class="fsize14 text-capitalize secondaryColor"
                @click="$store.commit('holdings/setCdslDialog', false);"
              >
                Close
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
</template>
<script>
import { mapState } from "vuex";
import customIcon from '../components/customIcon.vue'
export default {
    data(){
        return {
            errorAuth: null,
        }
    },
    components:{
        customIcon
    },
    computed:{
 ...mapState("holdings", [
      "cdslLoader",
      "pludgeTransId",
      "pludgeDpId",
      "pludgeReqId",
      "pludgeVersion",
      "pludgeUrl",
      "authorizeList",
      "revocationList",
      "isRevoke",
      'infoType'
    ]),
    ...mapState("settings", ["userDetails"]),
    checkboxSelectAll: {
      get: function () {
        var _checked = [];
        let list = this.isRevoke ? this.revocationList : this.authorizeList;
        list.forEach((item) => {
          _checked.push(item);
        });
        return list && _checked.length > 0
          ? this.selectedCheckbox.length == _checked.length
          : false;
      },
      set: function (value) {
        var checked = [];
        if (value) {
          let checkList = this.isRevoke
            ? this.revocationList
            : this.authorizeList;
          checkList.forEach((item) => {
            checked.push(item);
          });
        }
        this.selectedCheckbox = checked;
      },
    },
    selectedCheckbox: {
        get: function () {
        return this.$store.state.holdings.selectedCheckbox
      },
      set: function (newValue) {
        this.$store.commit("holdings/setSelectcheckData", newValue); 
      },
    }
    },
    methods:{ 
      keyPressNumeric(event) {
      this.errorAuth = null
      var inp = String.fromCharCode(event.keyCode);
      if (/[0-9]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    },
    setAuthData(item) {
      this.errorAuth = null;
      if (this.selectedCheckbox.length == 0) {
        this.selectedCheckbox.push(item);
      } else {
        const index = this.selectedCheckbox.indexOf(item);

        Number(index) == -1
          ? this.selectedCheckbox.push(item)
          : this.selectedCheckbox.splice(index, 1);
      }
    },
       async validateAuthrise() {
      if (this.selectedCheckbox.length == 0) {
        this.errorAuth = "Please select a scrips";
      } else {
        var d = new Date();
        var month = d.getMonth() + 1;
        var dformat = [
          d.getDate() < 10 ? "0" + d.getDate() : d.getDate(),
          month < 10 ? "0" + month : month,
          d.getFullYear(),
        ].join("");
        var tempRecordDtls = [];
        // var count = 0
        
        for (let item of this.selectedCheckbox) {
          // Number(item.tempQty) > 0 && Number(item.tempQty) <= Number(item.SellableQty) ? '' : count++
          // this.isRevoke ? item.SellableQty : item.tempQty
          var tempData = {
            isIn: item.isin,
            quantity: item.SellableQty,
          };
          tempRecordDtls.push(tempData);
        }
        // }(this.userDetails["dpAccountNumber"] && this.isRevoke) || (count == 0 && this.userDetails["dpAccountNumber"] && !this.isRevoke)
        if (this.userDetails["dpAccountNumber"]) {
          let checkBoId =
            this.userDetails["dpAccountNumber"].length == 16
              ? this.userDetails["dpAccountNumber"]
              : "12085300" + this.userDetails["dpAccountNumber"];
          var transDtls = {
            reqType: "D",
            reqIdentifier: "S",
            boId: checkBoId,
            exId: "12",
            numberOfDays: "1",
            execDate: dformat.trim().toString(),
            recordDtls: tempRecordDtls,
          };
          let jsonObject = {
            dpId: "85300",
            transDtls: transDtls,
          };
          this.isRevoke
            ? await this.$store.dispatch("holdings/callRevocation", jsonObject)
            : await this.$store.dispatch("holdings/callCdsl", jsonObject);
        }
        // else{
        //   this.errorAuth = "selected scrip(s) qty should be greater than zero and less than avilable qty";
        // }
      }
    },
      isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    },
    mounted(){
         if(this.$router.currentRoute.path == '/cdslmobile' && this.$router.currentRoute.query.hasOwnProperty('isRevoke')){
          this.$store.dispatch("settings/getCustomerDetails")
         this.$store.dispatch("holdings/getHoldings",{tab: this.infoType,from: 'commonAuthorize'})
      }

    },
    destroyed(){
      this.$router.currentRoute.query.hasOwnProperty('isRevoke') ? localStorage.clear() : ''
    }
}
</script>
<style>

</style>
